import React from "react"
import { ReactQueryProvider } from "~/utils/fetch/ReactQueryProvider"

interface ExternalPageProps {
  children: any
}

export function ExternalPage(props: ExternalPageProps) {
  return <ReactQueryProvider>{props.children}</ReactQueryProvider>
}
