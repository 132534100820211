import { useQuery } from "react-query"
import { LivestreamTypeExternal } from "@core/types/domain/Livestream"
import { AUTH_HEADER, fetchApi, requireOk, toJson } from "~/services/fetchApi"
import { toDate } from "~/utils/jsUtils"

export function useExternalLivestream(id: number, code: string) {
  return useQuery(
    ["livestreamExternal", id, code],
    () => fetchExternalLivestream(id, code),
    {
      enabled: !!id,
    }
  )
}

function fetchExternalLivestream(
  id: number,
  code: string
): Promise<LivestreamTypeExternal> {
  return fetchApi(`/livestream/${id}/external`, {
    headers: {
      [AUTH_HEADER]: `Code ${code}`,
    },
  })
    .then(requireOk)
    .then(toJson)
    .then(parseExternalStream)
}

export function parseExternalStream(
  json: LivestreamTypeExternal<string>
): LivestreamTypeExternal {
  return {
    ...json,
    plannedStart: toDate(json.plannedStart),
    plannedEnd: toDate(json.plannedEnd),
    end: toDate(json.end),
  }
}
