import React from "react"
import styled from "@emotion/styled"
import BaseLayout from "~/layouts/BaseLayout"
import Header from "~/layouts/main/header/Header"
import { WithChildren } from "~/types/reactTypes"

interface BlankLayoutProps extends WithChildren {}

function MinimalLayout(props: BlankLayoutProps) {
  return (
    <BaseLayout>
      <Header hideMenu />
      <div className="columns is-fullheight">
        <MainContent className={"column section"}>{props.children}</MainContent>
      </div>
    </BaseLayout>
  )
}

const MainContent = styled.div`
  padding: 32px;
`

export default MinimalLayout
