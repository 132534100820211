import React from "react"
import MinimalLayout from "~/layouts/blank/MinimalLayout"
import { ExternalLivestreamView } from "~/screens/external/stream/ExternalLivestreamView"
import { ExternalPage } from "~/layouts/external/ExternalPage"

export function ExternalLivestreamScreen() {
  return (
    <ExternalPage>
      <MinimalLayout>
        <ExternalLivestreamView />
      </MinimalLayout>
    </ExternalPage>
  )
}
